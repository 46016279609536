import React from 'react'
const Home = () => {
    return (
        <main className="Home">
            <p style={{ marginTop: '2rem' }}>Welcome to the Salem Portal</p>
        </main>
    )
}

export default Home
