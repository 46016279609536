/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import axios from 'axios'
import {
    Container,
    Grid,
    Chip,
    TextField,
    Button,
    Snackbar,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography
} from '@material-ui/core'

import { withStyles } from '@material-ui/styles'
import { Autocomplete, Alert } from '@material-ui/lab'
import { getDM } from './getDM'
import { getQueue } from './getQueue'
const styles = () => ({
    box: {
        marginTop: '24px',
    },
    resText: {
        fontWeight: 'bold',
        margin: 10,
    },
    divider: {
        background: '#1996a8',
        width: '80%',
        height: '5px',
    },
    container: {
        background: 'white',
        paddingBottom: '60px',
        paddingTop: '40px'
    },
    title: {
        marginBottom: '20px'
    }
})
class EvnInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            queueButton: 'Sample',
            severity: '',
            SnackbarText: '',
            dataModel: {},
            textInput: {
                alert_title: '',
                context: {
                    action: [],
                    related_actions: [],
                    account: [],
                    src_account: [],
                    dest: [],
                    src: [],
                    program: [],
                    parent_program: [],
                    data: [],
                },
                disposition: '',
                incident: null
            },
            open: false,
            rec: {},
        }
    }
    
    componentDidMount() {
        this.getDMC()
    }

    post = async (uri, data) => {
        const res = await axios({
            method: 'post',
            url: `/api/${uri}`,
            headers: {
                'context-type': 'application/json',
            },
            data: data,
        })
        return res
    }

    userDetails = async () => {
        const response = await fetch('/.auth/me')
        if (!response.ok) {
            return
        }
        const res = await response.json()
        const user = {
            userId: res.clientPrincipal.userId,
            userName: res.clientPrincipal.userDetails,
        }
        return user
    }

    sendtoDb = async () => {
        const userInfo = await this.userDetails()
        const time = new Date()
        const data = {
            sample: {
                alert:  this.state.textInput,
                user: userInfo,
                time: time.toISOString()
            }
        }
        const response = await this.post('sample/add/context-samples', data)
        const updateState = this.state
        if ((response).status === 200) {
            updateState['severity'] = 'success'
            updateState['SnackbarText'] = 'Success, Thanks for assist!'
        } else {
            updateState['severity'] = 'error'
            updateState['SnackbarText'] = 'Failed to submit!'
        }
        updateState['open'] = true
        updateState.textInput.incident = null
        this.setState(updateState)
    }

    getQue = async () => {
        this.setState({ alert_title: '' })
        this.setState({ queueButton: 'Next' })
        const queuemsg = await getQueue()
        if (queuemsg) {
            this.setState({ textInput: queuemsg })
        }
        
    }

    getDMC = async () => {
        const dm = await getDM()
        this.setState({ dataModel: dm })
    }

    handleChange = (_event, newValue, key) => {
        var t = []
        newValue.forEach((e) => {
            if (this.state.dataModel[key].includes(e)) {
                t = t.concat([e])
            } 
        })
        const elm = this.state
        elm.textInput.context[key] = t
        this.setState(elm)
    }

    handleTitleChange = (event) => {
        const updateState = this.state
        updateState.textInput['alert_title'] = event.target.value
        this.setState(updateState)
    }

    handleDispositionChange = (event) => {
        const updateState = this.state
        updateState.textInput['disposition'] = event.target.value
        this.setState(updateState)
    }

    handleIncidentChange = (event) => {
        const updateState = this.state
        updateState.textInput['incident'] = event.target.value
        this.setState(updateState)
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    renderTitleInput = () => {
        return (
            <Grid container>
                <Grid item md={12}>
                    <TextField
                        multiline
                        style = {{width: 400}}
                        label="Alert Title"
                        value={this.state.textInput.alert_title}
                        onChange={(event) => this.handleTitleChange(event)}
                    />
                </Grid>
            </Grid>
        )
    }

    renderDispositionInput = () => {
        return (
            <Grid item md={8}>
                <TextField
                    multiline
                    style = {{width: '100%', paddingRight: '10px'}}
                    label="Alert Disposition Description"
                    value={this.state.textInput.disposition}
                    onChange={(event) => this.handleDispositionChange(event)}
                />
            </Grid>
        )
    }

    renderIncidentInput = () => {
        return (
            <Grid item md={4}>
                <FormControl>
                    <InputLabel htmlFor="disposition_select">Disposition</InputLabel>
                    <Select
                        id='disposition_select'
                        label="Disposition"
                        style = {{width: '400px'}}
                        value={this.state.textInput.incident}
                        onChange={(event) => this.handleIncidentChange(event)}
                    >
                        <MenuItem value={0}>False Positive</MenuItem>
                        <MenuItem value={1}>Incident</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        )
    }

    clearInputstate = () => {
        const clearedState = {
            alert_title: '',
            context: {
                action: [],
                related_actions: [],
                account: [],
                src_account: [],
                dest: [],
                src: [],
                program: [],
                parent_program: [],
                data: [],
            },
            incident: null
        }
        this.setState({ textInput: clearedState })
    }

    renderInputs = (classes) => {
        const fields = Object.keys(this.state.dataModel).map((k) => {
            return (
                <>
                    <Grid item lg={4} sx={12} key={k + '_key'}>
                        <Autocomplete
                            multiple
                            selectOnFocus
                            clearOnBlur
                            autoHighlight
                            disableClearable
                            value={this.state.textInput.context[k]}
                            id={k}
                            name={k}
                            key={k + '_autocomplete'}
                            options={this.state.dataModel[k]}
                            style={{ width: 240, marginLeft: 10 }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={option + '_' + k + '_input'}
                                        variant="outlined"
                                        label={option}
                                        style={{ background: '#F1F8FE' }}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={k.replace('_', ' ')}
                                    key={k + '_text'}
                                />
                            )}
                            onChange={(event, newValue) =>
                                this.handleChange(event, newValue, k)
                            }
                        />
                    </Grid>
                </>
            )
        })
        return (
            <>
                {fields}
                <Grid item xs={12} style={{ padding: 15 }} />
                {this.renderIncidentInput()}
                {this.renderDispositionInput()}
                <Grid item xs={12} style={{ padding: 15 }} />
                {this.renderSubmit(classes)}
            </>
        )
    }
    renderSubmit = () => {
        var n = 0
        Object.keys(this.state.textInput.context).forEach((key) => {
            n += this.state.textInput.context[key].length
        })
        if (this.state.textInput.incident != null && n >= 3 && this.state.textInput.disposition !== '') {
            return (
                <>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={4} md={2}>
                            <Button
                                id="submit"
                                variant="outlined"
                                href="#input"
                                style={{
                                    float: 'right',
                                    marginRight: 10,
                                    borderColor: '#1996a8',
                                    borderWidth: 2,
                                    color: '#1996a8',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => this.sendtoDb()}
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Button
                                id="sample"
                                variant="outlined"
                                style={{
                                    float: 'right',
                                    marginRight: 10,
                                    borderColor: '#1996a8',
                                    borderWidth: 2,
                                    color: '#1996a8',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => this.getQue()}
                            >
                                {this.state.queueButton}
                            </Button>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Button
                                id="clear"
                                variant="outlined"
                                style={{
                                    float: 'right',
                                    marginRight: 10,
                                    borderColor: '#1996a8',
                                    borderWidth: 2,
                                    color: '#1996a8',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => this.clearInputstate()}
                            >
                                Clear
                            </Button>
                        </Grid>
                        <Grid item xs={12} style={{ padding: 10 }} />
                    </Grid>
                </>
            )
        } else {
            return (
                <>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={4} md={2}>
                            <Button
                                id="sample"
                                variant="outlined"
                                style={{
                                    float: 'right',
                                    marginRight: 10,
                                    borderColor: '#1996a8',
                                    borderWidth: 2,
                                    color: '#1996a8',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => this.getQue()}
                            >
                                {this.state.queueButton}
                            </Button>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Button
                                id="clear"
                                variant="outlined"
                                style={{
                                    float: 'right',
                                    marginRight: 10,
                                    borderColor: '#1996a8',
                                    borderWidth: 2,
                                    color: '#1996a8',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => this.clearInputstate()}
                            >
                                Clear
                            </Button>
                        </Grid>
                        <Grid item xs={12} style={{ padding: 10 }} />
                    </Grid>
                </>
            )
        }
    }

    render() {
        const { classes } = this.props
        return (
            <Container className={classes.container}>
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 1, lg: 1 }}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.box}
                >
                    <Grid item className={classes.title}>
                        <Typography variant="h4">
                            Welcome to the Salem Data Cannon
                        </Typography>
                        <Typography variant='body1'>
                            Use this tool to create training data for Salem AI's models.  Ensure that the input provided represents realistic alert scenarios.
                        </Typography>
                    </Grid>
                    {this.renderTitleInput()}
                    {this.renderInputs(classes)}
                </Grid>
                <Snackbar
                    open={this.state.open}
                    autoHideDuration={5000}
                    onClose={this.handleClose}
                    key="bottom center"
                >
                    <Alert
                        severity={this.state.severity}
                        onClose={this.handleClose}
                    >
                        {this.state.SnackbarText}
                    </Alert>
                </Snackbar>
            </Container>
        )
    }
}
export default withStyles(styles)(EvnInput)
