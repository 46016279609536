import { Button } from '@material-ui/core'
import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => createStyles({
    nav: {
        padding: theme.spacing(1),
        width: "100%",
        position: "absolute",
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(4), 
        float: "right"
    }
}))

const login_button = (auth, classes) => {
        if (auth) {
            return (
                <Button href="/logout" className={classes.button}> logout </Button>
            )
        } 
        else {
            return (
                <Button href="/login" className={classes.button}> login </Button>
            )
        }
    }

const dc_button = (auth, classes) => {
        if (auth) {
            return (
                <>
                    <Button href="/cannon" className={classes.button}> Data Cannon </Button>
                    <Button href="/validator2" className={classes.button}> Validator </Button>
                </>
            )
        } 
        else {
            return
        }
    }
const Nav = () => {
    const [auth, setAuth] = React.useState(0)
    const classes = useStyles();
    React.useEffect(() => {
        const get_user = async () => {
            const response = await fetch('/.auth/me')
            const user = await response.json()
            if (user.clientPrincipal && user.clientPrincipal.userRoles.includes('authenticated')) {
                setAuth(1)
            } else {
                setAuth(0)
            }
        }
        get_user(setAuth).catch()
    },[])
    return (
        <div className={classes.nav}>
            {login_button(auth, classes)}
            {dc_button(auth, classes)}
            <Button href="/" className={classes.button}>Home</Button>
        </div>
    )

}

export default Nav
