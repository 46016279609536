import React from 'react'
import { render } from "react-dom";
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'

const root = document.getElementById('root');
render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>, root
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
