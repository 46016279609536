import Salem1 from '../Images/Salem1.png'
import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => createStyles({
    header: {
        padding: theme.spacing(1),
        backgroundColor: "#f1f8fe",
        alignContent: "left",
        width: "100%"
    }
}))
const Header = () => {
    const classes = useStyles()
    return (
        <header className={classes.header}>
            <img src={Salem1} alt="company logo" width={182} height={64} />
        </header>
    )
}

export default Header
