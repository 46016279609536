/* eslint-disable react/prop-types */
import React from 'react'
import axios from 'axios'
import {
    Container,
    Grid,
    Chip,
    TextField,
    Typography,
    Button, 
    List,
    ListItem,
    ListItemText
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { getDM } from './getDM'


const CONTEXT_THRESHOLD = 1

const useStyles = makeStyles((theme) => createStyles({
    root: {
        backgroundColor: "white",
        height: "100%",
        paddingBottom: theme.spacing(16)
    },
    title: {
        marginTop: theme.spacing(4),
        marginLeft: "5%"
    },
    column: {
        marginTop: theme.spacing(4)
    },
    columnRow: {
        width: "90%",
        marginRight: "5%",
        marginLeft: "5%",
        margin: theme.spacing(4),
        position: "relative",
    },
    chart: {
        position: "relative",
        marginTop: theme.spacing(4)
    },
    chip:{
        margin: theme.spacing(1)
    },
    bar:{
        height: "8px",
        backgroundColor: '#efefef',
        width: "100%",
        position: "absolute"
    }, 
    barFilled:{
        height: "8px",
        backgroundColor: '#4396a9',
        position: "absolute"
    },
    button: {
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(2),
        color: "#4396a9",
        borderColor: "#4396a9",
        borderWidth: '3px'
    },
    counter: {
        color: 'white',
        backgroundColor: '#4396a9',
        marginRight: theme.spacing(1)
    },
    row: {
        marginTop: theme.spacing(4)
    }
}))

const sampleContext = {
    action: [{label: "web", selected: 1},
    {label: "network_connection", selected: 1},
    {label: "success", selected: 1},
    {label: "typical_action", selected: 0},
    {label: "api", selected: 0},
    {label: "encrypted_protocol", selected: 0}],
    account: [{label: "regular user", selected: 1}],
    src: [{label: "workstation", selected: 1},
    {label: "windows", selected: 1},
    {label: "internal_resource", selected: 1}],
    dest: [{label: "IP_IOC", selected: 1},
    {label: "external_resource", selected: 0}]
}

const baseAlert = {
    context: {},
    prediction: -1,
    needed_context: []
}

const Validator2Render = ({Alert}) => {
    const [alert, setAlert] = React.useState(Alert)
    const [dm, setDM] = React.useState({})
    const classes = useStyles();
    React.useEffect(() => {
        const getDMC = async () => {
            const get_dm = await getDM()
            setDM(get_dm)
        }
        getDMC()
    }, [])

    const post = async (uri, data) => {
        const res = await axios({
            method: 'post',
            url: `/api/${uri}`,
            headers: {
                'context-type': 'application/json',
            },
            data: data,
        })
        let body = await res.data
        return body
    }

    const handleSubmit = async () => {
        
        let res = await post('predict', {alert: alert})
        let alert_update = { ...alert }
        alert_update.prediction = Math.floor(res.prediction[0] * 100)
        alert_update.needed_context = res.needed_context
        setAlert(alert_update)
    }

    const handleChange = (_event, newValue, key) => {
        var t = []
        newValue.forEach((e) => {
            if (dm[key].includes(e)) {
                t = t.concat([e])
            } 
        })
        let elm = { ...alert } 
        elm.context[key] = t
        setAlert(elm)
    }

    const renderInputs = () => {
        const fields = Object.keys(dm).map((k) => {
            return (
                <>
                    <Grid item lg={4} sx={12} key={k + '_key'}>
                        <Autocomplete
                            multiple
                            selectOnFocus
                            clearOnBlur
                            autoHighlight
                            disableClearable
                            value={alert.context[k]}
                            id={k}
                            name={k}
                            key={k + '_autocomplete'}
                            options={dm[k]}
                            style={{ width: 240, marginLeft: 10 }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={option + '_' + k + '_input'}
                                        variant="outlined"
                                        label={option}
                                        style={{ background: '#F1F8FE' }}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={k.replace('_', ' ')}
                                    key={k + '_text'}
                                />
                            )}
                            onChange={(event, newValue) =>
                                handleChange(event, newValue, k)
                            }
                        />
                    </Grid>
                </>
            )
        })
        return (
            <>
                {fields}
            </>
        )
    }

    const prediction = () => {
        return (
        <div className={classes.columnRow}>
            <Typography style={{position: "absolute", bottom: 0}}>Threat likelihood</Typography>
            <Typography style={{right: 0, bottom: 0, position: "absolute"}}>{alert.prediction <= 0 ? 'N/A': alert.prediction <= 35 ? 'Low' : alert.prediction <= 70 ? 'Medium' : 'High'}</Typography>
            <div className={classes.chart}>
                <div className={classes.bar}></div>
                <div className={classes.barFilled} style={{width: alert.prediction <=0 ? "0%": `${alert.prediction}%`}}></div>
            </div>
            
        </div>
        )
    }

    const neededContext = () => {
        return (
            <List>
                {alert.needed_context.map((item, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={item} />
                    </ListItem>
                ))}
             </List>
        )
    }

    const renderSubmit = () => {
        var n = 0
        Object.keys(alert.context).forEach((key) => {
            n += alert.context[key].length
        })
        if ( n >= CONTEXT_THRESHOLD ) {
            return (

                <Button
                    id="submit"
                    variant="outlined"
                    href="#input"
                    style={{
                        float: 'right',
                        marginRight: 10,
                        borderColor: '#1996a8',
                        borderWidth: 2,
                        color: '#1996a8',
                        fontWeight: 'bold',
                    }}
                    onClick={() => handleSubmit()}
                >
                    Submit
                </Button>

            )
        } else {
            return (
                <Button
                    id="submit"
                    variant="outlined"
                    href="#input"
                    disabled
                    style={{
                        float: 'right',
                        marginRight: 10,
                        borderColor: '#1996a8',
                        borderWidth: 2,
                        fontWeight: 'bold',
                    }}
                    onClick={() => handleSubmit()}
                >
                    Submit
                </Button>
            )
        }
    }

    return (
        
            <Grid container>
                <Grid item sm={12} className={classes.row}>
                    <Typography>Ask Salem to review a cyber alert</Typography>
                </Grid>
                    {renderInputs()}
                <Grid item sm={8} className={classes.row}>
                    {prediction()}
                </Grid>
                <Grid item sm={4} className={classes.row}>
                    {renderSubmit()}
                </Grid>
                <Grid item sm={8} className={classes.row}>
                    {neededContext()}
                </Grid>
            </Grid>
        
    )
}


const Validator2 = () => {
    const [id, setId] = React.useState(1)
    const classes = useStyles();
    return (
        <Container className={classes.root}>
            <Validator2Render key={id} Alert={{
                context: {},
                prediction: -1,
                needed_context: []
            }}/>
            <Button
                id="clear"
                variant="outlined"
                style={{
                    float: 'right',
                    marginTop: 20,
                    marginRight: 10,
                    borderColor: '#1996a8',
                    borderWidth: 2,
                    color: '#1996a8',
                    fontWeight: 'bold',
                }}
                onClick={() => setId(id + 1)}
            >clear</Button>
        </Container>
    )
}

export default Validator2