import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => createStyles({
    footer: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        backgroundColor: "#f1f8fe",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%"
    },
    footerText: {
        textAlign: "center",
        fontSize: "14px"
    }
}))

const Footer = () => {
    const classes = useStyles();
    const today = new Date()
    return (
        <div className={classes.footer}>
            <div className={classes.footerText}>Salem Cyber &copy; {today.getFullYear()}</div>
        </div>
    )
}

export default Footer
