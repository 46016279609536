import React from 'react'
import Header from './components/Header'
import Nav from './components/Nav'
import Footer from './components/Footer'
import Unauthorized from './components/Unauthorized'
import NotFound from './components/NotFound'
import { Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import EvnInput from './components/EvnInput'
import Validator from './components/validator'
import Validator2 from './components/validator2.'

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */

function App() {
    return (
        <div className="App">
            <Header />
            <Nav />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/cannon" element={<EvnInput />} />
                <Route path="/validator" element={<Validator />} />
                <Route path="/validator2" element={<Validator2 />} />
                <Route path="/unauthorized" element={<Unauthorized/>} />
                <Route path="/not-found" element={<NotFound/>} />
                <Route path="*" exact={true} element={<NotFound/>}/>
            </Routes>
            <Footer />
        </div>
    )
}

export default App
