/* eslint-disable react/prop-types */
import React from 'react'
import axios from 'axios'
import {
    Container,
    Grid,
    Chip,
    TextField,
    Typography,
    Tooltip,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => createStyles({
    root: {
        backgroundColor: "white",
        height: "100%",
        paddingBottom: theme.spacing(16)
    },
    title: {
        marginTop: theme.spacing(4),
        marginLeft: "5%"
    },
    column: {
        marginTop: theme.spacing(4)
    },
    columnRow: {
        width: "90%",
        marginRight: "5%",
        marginLeft: "5%",
        margin: theme.spacing(2),
        position: "relative",
    },
    chart: {
        position: "relative",
        marginTop: theme.spacing(4)
    },
    chip:{
        margin: theme.spacing(1)
    },
    bar:{
        height: "8px",
        backgroundColor: '#efefef',
        width: "100%",
        position: "absolute"
    }, 
    barFilled:{
        height: "8px",
        backgroundColor: '#4396a9',
        position: "absolute"
    },
    button: {
        marginLeft: theme.spacing(2),
        color: "#4396a9",
        borderColor: "#4396a9",
        borderWidth: '3px'
    },
    counter: {
        color: 'white',
        backgroundColor: '#4396a9',
        marginRight: theme.spacing(1)
    }
}))

const sampleContext = {
    action: [{label: "web", selected: 1},
    {label: "network_connection", selected: 1},
    {label: "success", selected: 1},
    {label: "typical_action", selected: 0},
    {label: "api", selected: 0},
    {label: "encrypted_protocol", selected: 0}],
    account: [{label: "regular user", selected: 1}],
    src: [{label: "workstation", selected: 1},
    {label: "windows", selected: 1},
    {label: "internal_resource", selected: 1}],
    dest: [{label: "IP_IOC", selected: 1},
    {label: "external_resource", selected: 0}]
}

const baseAlert = {
    alert_name: '',
    raw: '',
    context: {},
    
}

const Validator = () => {
    const [alert, setAlert] = React.useState(baseAlert)
    const classes = useStyles();
    React.useEffect(() => {
        // TODO did mount
    })

    const post = async (uri, data) => {
        const res = await axios({
            method: 'post',
            url: `/api/${uri}`,
            headers: {
                'context-type': 'application/json',
            },
            data: data,
        })
        let body = await res.data
        return body
    }

    const handleReset = () => {
        setAlert(baseAlert)
    }

    const handleSubmit = async () => {
        let normalized_context = {}
        Object.entries(alert.context).forEach(([key, value]) => { 
            normalized_context[key] = value.filter(
                item => item.selected === 1
            ).map(item => {
                return item.label
        })})
        let body = await post('processAlert', {alert: {...alert, context: normalized_context}})
        let update_context = {}
        Object.entries(alert.context).forEach(([key, value]) => { 
            update_context[key] = value.filter(
                item => item.selected === 1
            ).map(item => {
                return item
        })})

        body.needed_context.forEach(item => {
            let itemSplit = item.split('.')
            let key = itemSplit[0]
            let value = itemSplit[1]
            if (!update_context[key]) {
                update_context[key] = []
            }
            let index = update_context[key].findIndex((item) => item.label === value)
            if (index  === -1) {
                update_context[key].push({label: value, selected: 0})
            }
        })
        Object.entries(body.context).forEach(([key, values]) => {
             values.forEach(value => {
                if (update_context[key]) {
                    let index = update_context[key].findIndex((item) => item.label === value)
                    if (index >= 0) {
                        update_context[key][index] = {label: value, selected: 1}
                    } else {
                        update_context[key].push({label: value, selected: 1})
                    }
                } else {
                    update_context[key] = [{label: value, selected: 1}]
                }
             })
             update_context[key].sort((a,b) => (a.selected < b.selected) ? 1: ((b.selected < a.selected) ? -1 : 0))
        })
        
        let prediction = Math.floor(body.prediction[0] * 100)
        let count = 0
        Object.entries(update_context).forEach(([key, values]) => {
            values.forEach(value => {
                if (value.selected === 1) {
                    count += 1
                }
            })
        })
        if (count <= 3) {
            prediction = -1
        }
        setAlert({...alert, context: update_context, prediction: prediction})
    }

    const handleChipClick = (event, label, field) => {
        let update = {}
        update[field] = alert.context[field].map(value => {
            if (value.label === label) {
            return {...value, selected: (value.selected + 1) % 2}
            }
            return value
        })
        let context = {...alert.context, ...update}
        setAlert({...alert, context})
    }

    const handelAlertNameChange = (event) => {
        setAlert({...alert, alert_name: event.target.value})
    }

    const handelAlertBodyChange = (event) => {
        setAlert({...alert, raw: event.target.value})
    }

    return (
        <Container className={classes.root}>
            <Grid container>
                <Grid item sm={12}  className={classes.title}>
                <Typography>Ask Salem to review a cyber alert</Typography>
                </Grid>
                <Grid item md={6} sm={12} className={classes.column}>
                    <TextField
                        variant='outlined'
                        label="Alert Name"
                        value={alert.alert_name}
                        className={classes.columnRow}
                        onChange={handelAlertNameChange} />
                    <TextField 
                        multiline
                        maxRows={6}
                        minRows={6}
                        variant='outlined'
                        label="Alert Body"
                        value={alert.raw}
                        className={classes.columnRow}
                        onChange={handelAlertBodyChange} />
                    <div className={classes.columnRow}>
                        <Typography style={{position: "absolute", bottom: 0}}>Threat likelihood</Typography>
                        <Typography style={{right: 0, bottom: 0, position: "absolute"}}>{alert.prediction <= 0 ? 'N/A': alert.prediction <= 35 ? 'Low' : alert.prediction <= 70 ? 'Medium' : 'High'}</Typography>
                        <div className={classes.chart}>
                            <div className={classes.bar}></div>
                            <div className={classes.barFilled} style={{width: alert.prediction <=0 ? "0%": `${alert.prediction}%`}}></div>
                        </div>
                        
                    </div>
                </Grid>
                <Grid item md={6} sm={12}  className={classes.column}>
                    <div className={classes.columnRow}>
                        {Object.entries(alert.context).map(([field, value], index) => {
                            return (
                                <Accordion>
                                    <AccordionSummary>
                                        <Chip 
                                            className={classes.counter} 
                                            label={value.filter(item => {if (item.selected === 1) {return true} else {return false}}).length}/>
                                        <Typography>{field}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{flexWrap: "wrap"}}>
                                        {value.map((data, index) => {
                                            return (
                                                <Tooltip
                                                    title={data.label}
                                                >
                                                    <Chip
                                                        label={data.label}
                                                        key={index}
                                                        style={
                                                            {
                                                                backgroundColor: data.selected ? '#4396a9': '#efefef',
                                                                color: data.selected ? 'white': 'black'
                                                            }
                                                        }
                                                        onClick={(event) =>
                                                            handleChipClick(event, data.label, field)
                                                        }
                                                        className={classes.chip} />
                                                </Tooltip>
                                            )
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            )})}
                    </div>
                    <Button variant='outlined' className={classes.button} onClick={handleSubmit}>{Object.entries(alert.context).length === 0 ? "Submit": "Update"}</Button>
                    <Button variant='outlined' className={classes.button} onClick={handleReset}>Clear</Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Validator